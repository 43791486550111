import { OrderWithOrderCustomersDTOStatusIdEnum, PolicyDTO, PolicyTypeDTOCoverTypeEnum } from '@reposit/api-client';
import { Formik, FormikProps } from 'formik';
import { get } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React, { useCallback, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import poundIcon from '../../../assets/svg/pound-sterling.svg';
import { getBreakpoint } from '../../../base/style';
import Button from '../../../components/Button';
import { useConfirmationModal } from '../../../components/ConfirmationModal';
import DeadlinesCard from '../../../components/DeadlinesCard';
import { ExpandableContent } from '../../../components/ExpandableContent';
import FlashMessage from '../../../components/FlashMessage';
import { Input, RadioGroup } from '../../../components/FormFields';
import DatePicker from '../../../components/FormFields/DatePicker';
import FieldWithLabel from '../../../components/FormFields/FieldWithLabel';
import InlineTable from '../../../components/InlineTable';
import Loading from '../../../components/Loading';
import PropertyUpdate from '../../../components/PropertyUpdate';
import { ReopenModal, ReopenType } from '../../../components/ReopenModal';
import PolicyHeader from '../../../components/Reposit/PolicyHeader';
import RepositAdditionalInformation from '../../../components/Reposit/RepositAdditionalInformation';
import RepositCard from '../../../components/Reposit/RepositCard';
import RepositIntegrator from '../../../components/Reposit/RepositIntegrator';
import RepositNotes from '../../../components/Reposit/RepositNotes';
import RepositPrice from '../../../components/Reposit/RepositPrice';
import RepositStatus from '../../../components/Reposit/RepositStatus';
import RepositSwitch from '../../../components/Reposit/RepositSwitch';
import RepositTransferHistory from '../../../components/Reposit/RepositTransferHistory';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../components/Table';
import TenantList from '../../../components/TenantList';
import { A, Caption, Header2, P1, P2, P3, StyledLink } from '../../../components/Typography';
import { DATE_FORMAT } from '../../../constants/dates';
import { NumberFormat } from '../../../constants/number-formats';
import {
  ADD_GUARANTOR_STORE_KEY,
  DELETE_GUARANTOR_STORE_KEY,
  UPDATE_GUARANTOR_STORE_KEY,
} from '../../../redux/guarantor/guarantor.actions';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import {
  ADD_REPOSIT_NOTE_STORE_KEY,
  ADD_TENANT_ON_REPOSIT_STORE_KEY,
  clearTenancyOrderHasFetched,
  DELETE_REPOSIT_NOTE_STORE_KEY,
  discardRepositRequested,
  DISCARD_REPOSIT_STORE_KEY,
  EXTEND_OUTCOME_DEADLINE_STORE_KEY,
  fetchTenancyOrderRequested,
  FETCH_TENANCY_ORDER_STORE_KEY,
  REMOVE_TENANT_ON_REPOSIT_STORE_KEY,
  reopenRepositRequested,
  REOPEN_REPOSIT_STORE_KEY,
  setCurrentRepositActionModal,
  TRANSFER_REPOSIT_STORE_KEY,
  updateTenancyRequested,
  UPDATE_TENANCY_STORE_KEY,
  UPDATE_TENANT_DETAILS_STORE_KEY,
} from '../../../redux/reposit/reposit.actions';
import {
  getCurrentRepositActionModal,
  getCurrentRepositActionModalData,
  getTenancyOrderHasBeenFetched,
} from '../../../redux/reposit/reposit.selectors';
import { AppState } from '../../../redux/root.reducer';
import { getTenancyOrder, TenancyOrderWithActions } from '../../../redux/selectors';
import {
  fetchTenancyAnniversariesRequested,
  FETCH_TENANCY_ANNIVERSARIES_STORE_KEY,
} from '../../../redux/tenancy-anniversary/tenancy-anniversary.actions';
import { getDisplayAddress, getLatestPolicyDocument } from '../../../utils/common.utils';
import { twoDPRegex } from '../../../utils/regex/number.regex';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../../FlashMessage';
import { AddTenantModal } from './AddTenantModal';
import { DisableTopUpsModal } from './DisableTopUpsModal';
import { ExtendOutcomeDeadlineModal } from './ExtendOutcomeDeadlineModal';
import { RemoveTenantModal } from './RemoveTenantModal';
import { TenancyAnniversaries } from './TenancyAnniversaries';
import TenancyDateActions from './TenancyDateActions';

interface RepositViewProps {}

interface RepositViewParams {
  id: string;
}

interface TenancyDetailsForm {
  PPM: number;
  startDate?: Date;
  endDate?: Date;
  letOnly: string;
}

const Field = styled.div`
  @media screen and (min-width: ${getBreakpoint('lg')}) {
    max-width: 94%;
  }
`;

const PoliciesContainer = styled.div`
  padding: 10px 35px;
  margin-bottom: 5px;
`;

const FieldAlt = styled.div`
  @media screen and (min-width: ${getBreakpoint('lg')}) {
    max-width: 94%;
    float: right;
    width: 100%;
  }
`;

const TenancyType = styled.div`
  min-height: 86px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    float: right;
    max-width: 95%;
    width: 100%;
  }
`;

const Action = styled.div`
  padding: 24px 0 0;
  text-align: right;
`;

const ActionBar = styled.div`
  display: flex;
  padding: 24px 0;
  justify-content: flex-end;
  flex-wrap: wrap;
  row-gap: 10px;

  button {
    margin-right: 10px;
  }
`;

const ExtraInfoLabels = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const OrgNotes = styled(P3)`
  white-space: pre-line;
`;

export enum TenancyTypes {
  LET_ONLY = 'Let only',
  FULLY_MANAGED = 'Fully Managed',
}

const Schema = Yup.object().shape({
  PPM: Yup.number()
    .typeError('PPM must be a number')
    .min(250, 'PPM has to be above £250')
    .max(5000, 'PPM has to be below £5,000')
    .required('Required')
    .test('two-decimals', 'PPM must have a maximum of two decimal places', (value) => twoDPRegex.test(value)),
  startDate: Yup.date().typeError('Start date must be a valid date').required('Required'),
  endDate: Yup.date().typeError('End date must be a valid date').required('Required'),
  letOnly: Yup.string().required('Required'),
});

const ActionButton = styled(Button)`
  margin-left: auto;
`;

const AddTenantButton: React.FC = () => {
  const dispatch = useDispatch();
  return (
    <ActionButton
      buttonType={'secondary'}
      onClick={() => dispatch(setCurrentRepositActionModal({ name: 'repositAddTenantModal' }))}
    >
      Add Tenant
    </ActionButton>
  );
};

const RepositView: React.FC<RepositViewProps> = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams<RepositViewParams>();
  const fetchRepositDetails = useCallback(() => dispatch(fetchTenancyOrderRequested(id)), [dispatch, id]);
  const tenancyOrder = useSelector((state: AppState) => getTenancyOrder(state, { tenancyOrderId: id }));

  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([
    UPDATE_TENANCY_STORE_KEY,
    EXTEND_OUTCOME_DEADLINE_STORE_KEY,
  ]);
  const tenancyId = tenancyOrder && tenancyOrder.tenancy && tenancyOrder.tenancy.id;
  const [globalFlashMessagePayload, onDismissGlobalFlashMessage] = useFlashMessage([
    TRANSFER_REPOSIT_STORE_KEY,
    ADD_TENANT_ON_REPOSIT_STORE_KEY,
    REOPEN_REPOSIT_STORE_KEY,
    ADD_REPOSIT_NOTE_STORE_KEY,
  ]);

  const [tenantFlashMessagePayload, onDismissTenantFlashMessage] = useFlashMessage([
    ADD_TENANT_ON_REPOSIT_STORE_KEY,
    REMOVE_TENANT_ON_REPOSIT_STORE_KEY,
    ADD_GUARANTOR_STORE_KEY,
    UPDATE_GUARANTOR_STORE_KEY,
    DELETE_GUARANTOR_STORE_KEY,
    UPDATE_TENANT_DETAILS_STORE_KEY,
  ]);
  const [noteFlashMessagePayload, onDismissNoteFlashMessage] = useFlashMessage([DELETE_REPOSIT_NOTE_STORE_KEY]);
  const isLoadingSelector = createLoadingSelector([FETCH_TENANCY_ORDER_STORE_KEY, FETCH_TENANCY_ANNIVERSARIES_STORE_KEY]);
  const isLoading = useSelector(isLoadingSelector);
  const hasLoaded = useSelector(getTenancyOrderHasBeenFetched);
  const currentRepositModal = useSelector(getCurrentRepositActionModal);
  const currentRepositModalData = useSelector(getCurrentRepositActionModalData);
  const currentTenancyUserId = currentRepositModalData.tenancyUserId || '';

  const isSubmittingReopenSelector = createLoadingSelector([REOPEN_REPOSIT_STORE_KEY]);
  const isSubmittingReopen = useSelector(isSubmittingReopenSelector);

  const dataPickerRef = useRef<any>(null);
  const { renderConfirmationModal, showModal } = useConfirmationModal({
    onSubmit: (reason?: string) => handleDeactivate(reason),
    text: 'Are you sure you would like to deactivate this Reposit? Tenants who completed their payment for this Reposit will be refunded in full.',
    title: 'Deactivate!',
    enableDetailsField: true,
    loadingActions: [DISCARD_REPOSIT_STORE_KEY],
  });
  // clear page
  useEffect(() => {
    return function clearRepositView() {
      dispatch(clearTenancyOrderHasFetched());
    };
  }, [dispatch]);

  useEffect(() => {
    fetchRepositDetails();
  }, [id, fetchRepositDetails]);

  useEffect(() => {
    if (tenancyId) {
      dispatch(fetchTenancyAnniversariesRequested(tenancyId));
    }
  }, [tenancyId, dispatch]);

  const handleDeactivate = (reason?: string) => {
    if (tenancyOrder) {
      dispatch(discardRepositRequested(tenancyOrder.id, reason));
    }
  };

  const onSubmit = (values: any) => {
    if (tenancyOrder) {
      dispatch(
        updateTenancyRequested({
          tenancyId: tenancyOrder.tenancy.id,
          tenancyOrderId: tenancyOrder.id,
          updates: {
            ...values,
            PPM: Math.round(values.PPM * 100),
            letOnly: values.letOnly === TenancyTypes.LET_ONLY,
            startDate: values.startDate ? moment(values.startDate).format('YYYY-MM-DD') : null,
            endDate: values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : null,
          },
        })
      );
    }
  };

  const renderPolicyDocument = (policy: PolicyDTO) => {
    const document = getLatestPolicyDocument(policy);
    if (document) {
      return (
        <A href={document.url} target="_blank">
          Download
        </A>
      );
    }
    return <></>;
  };

  const renderRepositStatus = (tenancyOrder: TenancyOrderWithActions) => {
    let status = tenancyOrder.order.statusId as string;

    if (tenancyOrder.deletedAt) {
      status = 'DELETED';
    }

    if (tenancyOrder.tenancy.completedAt && !tenancyOrder.tenancy.checkout) {
      status = 'CLOSED';
    }

    if (tenancyOrder.tenancy.checkout) {
      status = 'CHECKED_OUT';
    }

    return <RepositStatus status={status} />;
  };

  const renderRepositDetails = (tenancyOrder: TenancyOrderWithActions) => {
    const policyStatus = get(tenancyOrder, 'order.policy.status', null);
    const organisation = get(tenancyOrder, 'tenancy.organization', {});
    const publisherUser = get(tenancyOrder, 'tenancy.publisherUser', {});
    const completedAt = get(tenancyOrder, 'tenancy.completedAt', {});
    const claim = get(tenancyOrder, 'tenancy.checkout.claim');
    if (isLoading || !tenancyOrder.tenancy) return <Loading />;
    const canReopen = completedAt && !claim;

    const { tenancy, order } = tenancyOrder;

    const integrator = order.integrator;
    const policies = order.policies;

    const signedDocumentTypes = ['SIGNED_ADDENDUM', 'SIGNED_TENANT_TERMS_AND_CONDITIONS'];
    const tenancyDocuments = get(tenancy, 'documents', []);
    const signedDocuments = tenancyDocuments.filter((d) => signedDocumentTypes.includes(d.typeId));

    const expiryDates = tenancyOrder.tenancy && tenancyOrder.tenancy.expiryDates;
    let originalEndDate = null;
    let orderedExpiryDates = null;
    if (expiryDates && expiryDates.length) {
      if (expiryDates.length === 1) {
        orderedExpiryDates = expiryDates;
      } else {
        orderedExpiryDates = expiryDates.sort((a, b) => (a.endDate <= b.endDate ? -1 : 1));
      }
      originalEndDate = orderedExpiryDates[0].endDate;
    }

    const repositNotes = tenancyOrder.tenancyOrderNotes;

    let policiesWithItems: any[] = [];
    if (policies) {
      policies.map((p) => {
        return policiesWithItems.push({
          ...p,
          data: [
            { key: 'Underwriter', value: p.type.underwriter.name },
            { key: 'Cover weeks', value: `${p.type.maxWeeksCover} weeks` },
            { key: 'Policy band', value: `£${numeral(p.type.coverLimit / 100).format(NumberFormat.THOUSANDS)}` },
            { key: 'Cover length', value: `${p.type.maxDuration} months` },
            { key: 'Inception', value: p.startDate },
            {
              key: 'Cover type',
              value: p.type.coverType === PolicyTypeDTOCoverTypeEnum.EXISTINGTENANCY ? 'Existing Tenancy' : 'New Tenancy',
            },
            { key: 'Expiry', value: p.endDate },
            {
              key: 'Cover limit',
              value: p.items[0] ? `£${numeral(p.items[0].sumInsured / 100).format(NumberFormat.THOUSANDS_PENCE)}` : 'No cover',
            },
            { key: 'Status', value: p.status },
            { key: 'Legacy insurer Id', value: p.legacyInsurerId || '-' },
            { key: 'Policy summary', value: renderPolicyDocument(p) },
            { key: 'Created', value: moment(p.createdAt).format('DD/MM/YYYY HH:mm:ss') },
          ],
        });
      });
    }
    // we need to display policies in the order created for Ops
    policiesWithItems = policiesWithItems.sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix());

    const tenancyOutcomeDeadline = get(tenancyOrder, 'tenancy.outcomeDeadline');
    const hasExtendedOutcomeDeadline = get(tenancyOrder, 'tenancy.hasExtendedOutcomeDeadline', false);
    const topUpEnabled = get(tenancyOrder, 'tenancy.topUpEnabled');

    const outgoingTenant = tenancy.tenants.find((t) => t.id === currentTenancyUserId);
    return (
      <>
        {currentRepositModal === 'repositRemoveTenantModal' && (
          <RemoveTenantModal
            currentTenancyUserEmail={outgoingTenant ? outgoingTenant.email : ''}
            tenancyId={tenancy.id}
            currentTenancyUserId={currentTenancyUserId}
          />
        )}
        {currentRepositModal === 'repositAddTenantModal' && <AddTenantModal tenancyId={tenancy.id} />}
        <Row>
          <Col md={6} lg={7}>
            <Header2>Reposit</Header2>
            <Caption>{getDisplayAddress(tenancy.property.address)}</Caption>
            {renderRepositStatus(tenancyOrder)}
          </Col>
          <Col md={6} lg={5}>
            <ExtraInfoLabels>
              {integrator ? <RepositIntegrator integratorName={integrator.name} /> : null}
              <RepositPrice orderStatus={order.statusId} orderAmount={order.amount} />
              {!tenancy.isNewTenancy ? <RepositSwitch /> : null}
            </ExtraInfoLabels>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <ActionBar>
              {!tenancyOrder.deletedAt && (
                <>
                  <Button buttonType="warning" onClick={() => showModal(true)}>
                    Deactivate
                  </Button>
                  {topUpEnabled && (
                    <>
                      <Button onClick={() => dispatch(setCurrentRepositActionModal({ name: 'disableTopUps' }))}>
                        Disable Annual Fees
                      </Button>
                      {currentRepositModal === 'disableTopUps' && <DisableTopUpsModal tenancyId={tenancy.id} />}
                    </>
                  )}
                  <NavLink to={`/reposits/${id}/transfer`}>
                    <Button>Transfer</Button>
                  </NavLink>
                  <NavLink to={`/reposits/${id}/add-note`}>
                    <Button>Add Note</Button>
                  </NavLink>
                  {!hasExtendedOutcomeDeadline && (
                    <>
                      <Button onClick={() => dispatch(setCurrentRepositActionModal({ name: 'outcomeDeadlineModal' }))}>
                        Extend Outcome Deadline
                      </Button>
                      {currentRepositModal === 'outcomeDeadlineModal' && <ExtendOutcomeDeadlineModal tenancyId={tenancy.id} />}
                    </>
                  )}
                  {canReopen && (
                    <>
                      <Button onClick={() => dispatch(setCurrentRepositActionModal({ name: 'reopenRepositModal' }))}>
                        Reopen Reposit
                      </Button>
                      {currentRepositModal === 'reopenRepositModal' && (
                        <ReopenModal
                          onSubmit={(date) => {
                            dispatch(
                              reopenRepositRequested({
                                tenancyOrderId: tenancyOrder.id,
                                outcomeDeadline: moment(date).format('YYYY-MM-DD'),
                                tenancyId: tenancyId as string,
                              })
                            );
                          }}
                          onDismiss={() => dispatch(setCurrentRepositActionModal({ name: '' }))}
                          type={ReopenType.REPOSIT}
                          isSubmitting={isSubmittingReopen}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </ActionBar>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            {globalFlashMessagePayload ? (
              <FlashMessage
                onDismiss={onDismissGlobalFlashMessage}
                timeRemaining={FLASH_MESSAGE_TIMEOUT}
                payload={globalFlashMessagePayload}
              />
            ) : undefined}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div style={{ position: 'relative', zIndex: 10 }}>
              <RepositCard
                title="Tenancy Details"
                tooltip="Property information consists of the address and landlord of the property. These two things together make up a property."
                flashMessage={
                  flashMessagePayload ? (
                    <FlashMessage
                      onDismiss={onDismissFlashMessage}
                      timeRemaining={FLASH_MESSAGE_TIMEOUT}
                      payload={flashMessagePayload}
                    />
                  ) : undefined
                }
              >
                <Formik
                  initialValues={{
                    PPM: tenancy.PPM,
                    startDate: tenancy.startDate ? moment(tenancy.startDate).toDate() : undefined,
                    endDate: originalEndDate ? moment(originalEndDate).toDate() : undefined,
                    letOnly: tenancy.letOnly ? TenancyTypes.LET_ONLY : TenancyTypes.FULLY_MANAGED,
                  }}
                  validationSchema={Schema}
                  onSubmit={onSubmit}
                >
                  {({
                    values,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    touched,
                    errors,
                    setFieldValue,
                  }: FormikProps<TenancyDetailsForm>) => (
                    <form onSubmit={handleSubmit}>
                      <Container fluid>
                        <Row>
                          <Col lg={6} style={{ padding: 0 }}>
                            <Field>
                              <FieldWithLabel label="PPM" error={errors.PPM} touched={touched.PPM}>
                                <Input
                                  name="PPM"
                                  value={`${values.PPM}`}
                                  onChange={handleChange}
                                  disabled={order.statusId !== OrderWithOrderCustomersDTOStatusIdEnum.DRAFT}
                                  onBlur={handleBlur}
                                  icon={poundIcon}
                                  error={errors.PPM}
                                  touched={touched.PPM}
                                />
                              </FieldWithLabel>
                            </Field>
                          </Col>
                          <Col lg={6} style={{ padding: 0 }}>
                            <TenancyType>
                              <FieldWithLabel label="Tenancy type" error={errors.letOnly} touched={touched.letOnly}>
                                <RadioGroup
                                  name="letOnly"
                                  options={[
                                    { value: TenancyTypes.FULLY_MANAGED, label: 'Fully Managed' },
                                    { value: TenancyTypes.LET_ONLY, label: 'Let Only' },
                                  ]}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={errors.letOnly}
                                  touched={touched.letOnly}
                                  selected={values.letOnly}
                                />
                              </FieldWithLabel>
                            </TenancyType>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} style={{ padding: 0 }}>
                            <Field>
                              <FieldWithLabel label="Start Date">
                                <DatePicker
                                  name="startDate"
                                  value={values.startDate}
                                  dateFormat={DATE_FORMAT}
                                  onBlur={handleBlur}
                                  touched={touched.startDate}
                                  placeholder={'DD/MM/YYYY'}
                                  disabled={policyStatus === 'ISSUED'}
                                  error={errors.startDate}
                                  minDate={moment().subtract(1, 'week').toDate()}
                                  onChange={(date) => {
                                    setFieldValue('startDate', date);
                                  }}
                                />
                              </FieldWithLabel>
                            </Field>
                          </Col>
                          <Col lg={6} style={{ padding: 0 }}>
                            <FieldAlt>
                              <FieldWithLabel label="Original End Date">
                                <DatePicker
                                  name="endDate"
                                  value={values.endDate}
                                  onBlur={handleBlur}
                                  dateFormat={DATE_FORMAT}
                                  touched={touched.endDate}
                                  error={errors.endDate}
                                  forwardedRef={dataPickerRef}
                                  disabled={policyStatus === 'ISSUED'}
                                  children={
                                    <TenancyDateActions
                                      initialDate={values.startDate}
                                      onDateSelect={(date) => {
                                        dataPickerRef.current.setOpen(false);
                                        setFieldValue('endDate', date);
                                      }}
                                    />
                                  }
                                  placeholder={'DD/MM/YYYY'}
                                  minDate={moment(values.startDate).add(1, 'days').toDate()}
                                  maxDate={moment(values.startDate).add(2, 'years').toDate()}
                                  onChange={(date) => setFieldValue('endDate', date)}
                                />
                              </FieldWithLabel>
                            </FieldAlt>
                          </Col>
                        </Row>
                        {!tenancyOrder.deletedAt && (
                          <Row>
                            <Col lg={12} style={{ padding: 0 }}>
                              <Action>
                                <Button buttonType="secondary">Update</Button>
                              </Action>
                            </Col>
                          </Row>
                        )}
                      </Container>
                    </form>
                  )}
                </Formik>
              </RepositCard>
            </div>
          </Col>
        </Row>
        {signedDocuments.length ? (
          <Row>
            <Col lg={12}>
              <RepositCard title="Tenancy Documents">
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Document Type</Th>
                      <Th>Document</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {signedDocuments.map((document) => (
                      <Tr key={document.id}>
                        <Td>{document.name}</Td>
                        <Td>
                          <A href={document.url} target="_blank">
                            Download
                          </A>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </RepositCard>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col lg={claim ? 6 : 12}>
            <RepositCard title="Organisation">
              <P1>
                {organisation.name} - ({organisation.organizationTypeId})
              </P1>
              {publisherUser && (
                <P2>
                  Published by{' '}
                  <StyledLink
                    to={`/users/${publisherUser.id}`}
                  >{`${publisherUser.firstName} ${publisherUser.lastName}`}</StyledLink>
                </P2>
              )}
              {organisation.attributes.notes && (
                <>
                  <P2>Notes:</P2>
                  <OrgNotes>{organisation.attributes.notes}</OrgNotes>
                </>
              )}
              <NavLink to={`/organisations/${organisation.id}`}>
                <Button buttonType="secondary">View Organization</Button>
              </NavLink>
            </RepositCard>
          </Col>
          {claim && (
            <Col lg={6}>
              <RepositCard title="Claim">
                <P1 style={{ marginBottom: 56 }}>
                  Published at: {claim.publishedAt ? moment(claim.publishedAt).format('DD/MM/YYYY HH:mm') : 'N/A'}
                </P1>
                <NavLink to={`/claims/${claim.id}`}>
                  <Button buttonType="secondary">View Claim</Button>
                </NavLink>
              </RepositCard>
            </Col>
          )}
        </Row>
        <Row>
          <Col lg={12}>
            <RepositAdditionalInformation tenancyOrder={tenancyOrder} />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            {noteFlashMessagePayload ? (
              <FlashMessage
                onDismiss={onDismissNoteFlashMessage}
                timeRemaining={FLASH_MESSAGE_TIMEOUT}
                payload={noteFlashMessagePayload}
              />
            ) : undefined}
          </Col>
        </Row>
        {repositNotes && repositNotes.length > 0 ? (
          <Row>
            <Col lg={12}>
              <RepositNotes tenancyOrder={tenancyOrder} />
            </Col>
          </Row>
        ) : undefined}
        <Row>
          <Col lg={12}>
            <RepositTransferHistory tenancyOrder={tenancyOrder} />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <DeadlinesCard title="Reposit deadlines" tenancyOutcomeDeadline={tenancyOutcomeDeadline} />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <PropertyUpdate property={tenancy.property} tenancyOrderId={tenancyOrder.id} tenancyId={tenancy.id} />
          </Col>
        </Row>
        {orderedExpiryDates && orderedExpiryDates.length > 1 ? (
          <Row>
            <Col lg={12}>
              <RepositCard flush title="Extensions">
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Id</Th>
                      <Th>Extended At</Th>
                      <Th>New End Date</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {orderedExpiryDates &&
                      orderedExpiryDates.map((date, index) =>
                        index > 0 ? (
                          <Tr key={date.id}>
                            <Td>{date.id}</Td>
                            <Td>{moment(date.createdAt).format('DD/MM/YYYY hh:ss')}</Td>
                            <Td>{date.endDate}</Td>
                          </Tr>
                        ) : undefined
                      )}
                  </Tbody>
                </Table>
              </RepositCard>
            </Col>
          </Row>
        ) : undefined}
        {order && order.policies && order.policies.length ? (
          <Row>
            <Col lg={12}>
              <RepositCard flush title="Policies" tooltip="lorem">
                {policiesWithItems.map((p) => {
                  return (
                    <PoliciesContainer key={p.id}>
                      <ExpandableContent header={<PolicyHeader policy={p} />} hiddenContent={<InlineTable items={p.data} />} />
                    </PoliciesContainer>
                  );
                })}
              </RepositCard>
            </Col>
          </Row>
        ) : undefined}
        <Row>
          <Col lg={12}>
            <RepositCard
              flush
              title="Tenants"
              flashMessage={
                tenantFlashMessagePayload ? (
                  <FlashMessage
                    onDismiss={onDismissTenantFlashMessage}
                    timeRemaining={FLASH_MESSAGE_TIMEOUT}
                    payload={tenantFlashMessagePayload}
                  />
                ) : undefined
              }
              headerContent={<AddTenantButton />}
            >
              <TenantList
                tenants={tenancy.tenants}
                tenancyId={tenancy.id}
                onDeleteClick={(tenancyUserId) =>
                  dispatch(setCurrentRepositActionModal({ name: 'repositRemoveTenantModal', data: { tenancyUserId } }))
                }
                tenancyOrderStatus={(tenancyOrder && tenancyOrder.order.statusId) || ''}
              />
            </RepositCard>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <TenancyAnniversaries />
          </Col>
        </Row>
        {renderConfirmationModal()}
      </>
    );
  };

  return <Container>{!isLoading && hasLoaded && tenancyOrder ? renderRepositDetails(tenancyOrder) : <Loading />}</Container>;
};

export default RepositView;
